<template>
  <div class="event-card-wrapper mb-3 me-0 me-lg-4">
    <div class="event-card">
      <div class="share-love-wrapper">
        <div class="share-btn"><i class="bi bi-share"></i></div>
        <div class="love-btn"><i class="bi bi-heart-fill"></i></div>
      </div>
      <img :src="eventImage" alt="" />

      <div
        class="d-flex align-items-center justify-content-between title-n-price-wrapper"
      >
        <h1 class="title">{{ eventTitle }}</h1>
        <p class="price">{{ eventPrice }}</p>
      </div>
      <p class="time">{{ eventDate }},&nbsp;{{ eventTime }}</p>
      <p class="location">
        <i class="bi bi-geo-alt-fill"></i>
        <span class="location-txt"
          >{{ eventAddress }},&nbsp;{{ eventCity }}</span
        >
      </p>
      <div class="d-flex align-items-center justify-content-between">
        <p class="hosted">
          Hosted by <span class="host">{{ eventHost }}</span>
        </p>
        <div class="d-flex align-items-center">
          <p
            class="bg-secondary fs-6 text-white"
            style="
              margin-bottom: -4px;
              padding: 1px 7px;
              border-radius: 5px;
              text-transform: uppercase;
            "
          >
            {{ eventType }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EventCard",
  props: [
    "eventImage",
    "eventTitle",
    "eventPrice",
    "eventDate",
    "eventTime",
    "eventAddress",
    "eventCity",
    "eventType",
    "eventHost",
  ],
  setup() {},
};
</script>

<style scoped>
.event-card-wrapper {
  width: 30.5%;
  border-radius: var(--br-6);
  border-top: 1px solid var(--blackie);
  border-right: 3px solid var(--blackie);
  border-bottom: 3px solid var(--blackie);
  border-left: 1px solid var(--blackie);
  padding: 24px 16px;
  box-sizing: border-box;
  background-color: #fff;
  cursor: pointer;
}
.event-card {
  position: relative;
}
.share-love-wrapper {
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 1;
  display: flex;
}

.share-btn,
.love-btn {
  background-color: rgba(255, 255, 255, 0.26);
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.share-btn > i,
.love-btn > i {
  font-size: 20px;
  color: #fff;
}
.love-btn {
  margin-left: 10px;
}

.event-card > img {
  width: 100%;
  height: 190px;
  object-fit: cover;
  border-radius: var(--br-6);
}
.title-n-price-wrapper {
  margin: 24px 0 16px 0;
}

.title,
.time,
.price,
.location,
.hosted {
  margin: 0;
}
.title {
  color: #202020;
  font-family: var(--ff-roboto);
  font-size: 24px;
  font-weight: 600;
}
.price {
  color: var(--lust);
  font-family: var(--ff-roboto);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.time {
  color: var(--lust);
  font-family: var(--ff-poppins);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 16px;
}

.location {
  color: #525252;
  font-family: var(--ff-poppins);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 16px;
}

.hosted {
  font-family: var(--ff-poppins);
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  color: #525252;
}
.host {
  color: var(--lust);
}

@media screen and (min-width: 600px) and (max-width: 992px) {
  .event-card-wrapper {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .event-card-wrapper {
    width: 100%;
  }
}
</style>
