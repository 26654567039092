<template>
  <!-- EVENT SEARCH BLOCK SECTION STARTS HERE -->
  <div class="event-search-block">
    <div class="event-search">
      <input
        type="text"
        placeholder="Name of Event"
        class="input-lg search-event form-control"
        v-model="formData.event_name"
      />
      <select
        class="select-lg form-select shadow-none select-category"
        v-model="formData.category"
      >
        <option value="" disabled>Select Category</option>

        <option
          v-for="(category, index) in eventsCategories"
          :key="index"
          :value="category.category"
        >
          {{ category.category }}
        </option>
      </select>
      <!-- <select class="select-lg form-select shadow-none select-date">
        <option value="">Today</option>
        <option value="">One</option>
        <option value="">Two</option>
      </select> -->
      <input
        type="date"
        class="select-date form-control"
        v-model="formData.date"
      />
      <button
        class="button button-lg ms-lg-3 search-button"
        @click="getSearchedEvent"
      >
        Search
      </button>
    </div>
  </div>
</template>

//
<script>
import { ref, onMounted } from "vue";
import { useCreateEventStore } from "@/store/createEvents";
import { useGetEventStore } from "@/store/getEvents";
import { useToast } from "vue-toastification";
export default {
  name: "EventSearchBlock",
  setup(_, { emit }) {
    const toast = useToast();
    const eventsCategories = ref("");
    const searchedEvent = ref("");
    const event = useCreateEventStore();
    const events = useGetEventStore();

    const formData = ref({
      event_name: "",
      category: "",
      date: "",
    });

    const getSearchedEvent = async () => {
      try {
        const response = await events.searchEventHome(formData.value);
        if (response.status === 201) {
          searchedEvent.value = response.data.data;
          emit("searchedEvent", searchedEvent.value);
          toast.success(response.data.message, { timeout: 2000 });
        }
        // Emit this response out to Home

        if (response.status === 404) {
          toast.error(response.data.message, { timeout: 2000 });
          formData.value.category = "";
          formData.value.date = "";
          formData.value.event_name = "";
        }
        return response;
      } catch (error) {
        return error;
      }
    };

    const getEventsCategories = async () => {
      try {
        const response = await event.getCategories();
        eventsCategories.value = response.data.data;
      } catch (error) {
        return error;
      }
    };

    onMounted(() => {
      getEventsCategories();
    });

    return { formData, eventsCategories, getSearchedEvent };
  },
};
</script>

<style scoped>
/* Event search block styling starts here */
.event-search-block {
  width: 75%;
  padding: 40px 43px;
  background: #101010;
  margin: auto;
  position: relative;
  top: -50px;
}
.event-search {
  display: flex;
}
.event-search-block > div > input,
select {
  outline: none;
  border: none;
}
.select-lg {
  padding: 16px 24px 17px 24px;
  border-left: 2px solid black;
}

.search-button,
.select-date,
.select-category,
.search-event {
  flex-shrink: 1;
  width: 320px;
  font-size: 18px;
}
input[type="date"] {
  display: block;
  appearance: textfield;
}

/* Event Search Block Responsiveness */
@media screen and (max-width: 992px) {
  .event-search-block {
    width: 100%;
  }
  .event-search {
    display: block;
  }
  .search-button,
  .select-date,
  .select-category,
  .search-event {
    padding: 16px 24px;
    border-radius: 16px;
    width: 100% !important;
    margin-bottom: 20px;
    font-size: 16px;
  }
}
</style>
