<template>
  <div class="preloader-wrapper" :class="{ 'stop-preloader': showPreloader }">
    <div class="preloader-img">
      <img :src="preloaderImg" alt="Afro verified image preloader" />
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
export default {
  name: "preloaderComp",
  setup() {
    const preloaderImg = "/img/afroverified_loader.gif";
    const showPreloader = ref(false);
    const isPreloading = () => {
      // setTimeout(() => {
      showPreloader.value = true;
      // }, 3000);
    };

    onMounted(() => {
      setTimeout(isPreloading, 2000);
    });
    return { preloaderImg, showPreloader };
  },
};
</script>

<style scoped>
.preloader-wrapper {
  background-color: black;
  width: 100%;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 1021;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 300ms ease-in;
}
.stop-preloader {
  animation: 1s stopPreloader forwards;
}
@keyframes stopPreloader {
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.preloader-img {
  border: 2px solid black;
  width: 300px;
}
.preloader-img > img {
  width: 100%;
}
</style>
