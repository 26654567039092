<template>
  <nav class="navbar sticky-top navbar-expand-lg">
    <div class="container">
      <router-link to="/">
        <NavBrand />
      </router-link>
      <button
        class="navbar-toggler shadow-none"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i class="bi bi-list text-white fs-3"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link :to="{ name: 'home' }" class="nav-link" exact
              >Home</router-link
            >
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'blog' }" class="nav-link" exact
              >Blog</router-link
            >
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'aboutus' }" class="nav-link" exact
              >About us</router-link
            >
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'events' }" class="nav-link" exact
              >Events</router-link
            >
          </li>
        </ul>
        <ul class="navbar-nav mb-2 mb-lg-0 d-lg-flex align-items-lg-center">
          <li class="nav-item" v-show="!isLoggedIn">
            <router-link :to="{ name: 'signin' }" class="nav-link"
              >Sign in</router-link
            >
          </li>
          <li v-show="auth.user.type === 'vendor'">
            <button class="button nav-btn" @click="goToCreateEvent">
              Create Event
            </button>
          </li>

          <li class="nav-item dropdown ms-3" v-show="isLoggedIn">
            <a
              class="dropdown-toggle text-white"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span class="user-avatar">
                <img :src="userAvatar" alt="" />
              </span>
              <!-- Dropdown -->
            </a>
            <ul class="dropdown-menu">
              <li class="link" v-show="auth.user.type === 'vendor'">
                <router-link :to="{ name: 'account' }" class="inner-link"
                  >My Profile</router-link
                >
              </li>
              <!-- WILL UNCOMMENT THIS LATER IN THE FUTURE -->
              <!-- <li class="link" v-show="auth.user.type === 'user'">
                <router-link to="{name : 'account'}" class="inner-link"
                  >My Profile</router-link
                >
              </li> -->
              <!-- <li class="link">
                <router-link to="/" class="inner-link"
                  >Saved Events</router-link
                >
              </li> -->
              <li class="link" v-show="auth.user.type === 'vendor'">
                <router-link :to="{ name: 'bookings' }" class="inner-link"
                  >My Bookings</router-link
                >
              </li>
              <li class="link" v-show="auth.user.type === 'user'">
                <router-link
                  :to="{ name: 'customer-bookings' }"
                  class="inner-link"
                  >My Bookings</router-link
                >
              </li>
              <li class="logout-link" @click="signOut">Sign Out</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { onMounted, ref } from "vue";
import NavBrand from "./NavBrand.vue";
import { useAuthStore } from "@/store/auth";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";
export default {
  name: "NavbarComp",
  components: { NavBrand },
  setup() {
    const toast = useToast();
    const router = useRouter();
    const isLoggedIn = ref(false);
    const auth = useAuthStore();

    const userAvatar = ref("");
    const defaultLogo = "219959.png";
    const defaultAvatar = `${process.env.VUE_APP_IMAGE_PREVIEW}${defaultLogo}`;
    const userInfo = auth.userData.display_image;

    if (userInfo === null) {
      userAvatar.value = defaultAvatar;
    } else {
      userAvatar.value = `${process.env.VUE_APP_IMAGE_PREVIEW}${auth.userData.display_image}`;
    }

    const goToCreateEvent = () => {
      router.push({ name: "basic" });
    };

    const signOut = async () => {
      try {
        const response = await auth.logout();
        if (response.status === 200) {
          toast.success("Logged out successfully", { timeout: 2000 });
          localStorage.clear();
          window.location.reload();
          // window.location.reload();
        }
        const errMessage = response.message;
        const regex = /\b401\b/;
        const isContain401 = regex.test(errMessage);
        if (isContain401) {
          toast.success("Logged out successfully", { timeout: 2000 });
          localStorage.clear();
          window.location.reload();
        }
      } catch (error) {
        return error;
      }
    };

    onMounted(() => {
      isLoggedIn.value = auth.isLoggedIn;
    });

    return {
      isLoggedIn,
      userAvatar,
      defaultAvatar,
      auth,
      goToCreateEvent,
      signOut,
    };
  },
};
</script>

<style scoped>
nav {
  background-color: #000;
}
.router-link-active {
  color: red !important;
}
/* Styling for dropdpwn menu */
.dropdown-menu {
  top: 70px;
  left: -150px !important;
  transition: left 400ms ease-in;
  width: 250px;
  padding: 25px 25px 5px 25px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.link,
.logout-link {
  margin-bottom: 20px;
  cursor: pointer;
}
.link > .inner-link,
.logout-link {
  color: #525252 !important;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 27.5px;
}
.user-avatar > img {
  width: 45px;
  height: 45px;
  border-radius: 100%;
}
@media screen and (min-width: 1000px) {
  .nav-link {
    margin-right: 20px;
  }
}
</style>
