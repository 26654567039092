<template>
  <div class="upcoming-event-wrapper">
    <div class="container">
      <!-- EVENT SEARCH BLOCK SECTION STARTS HERE -->
      <EventSearchBlock @searchedEvent="searchedEventData" />

      <div class="upcoming-event">
        <h1>Upcoming Events</h1>

        <!-- UPCOMING EVENTS STARTS HERE -->
        <UpcomingEventList :eventSearchedData="eventSearchedDatas" />

        <!-- EVENTS FOR YOU SECTION STARTS HERE -->
        <div class="event-4-u">
          <h1>Events for you</h1>
          <!-- <div
            class="event-4-u-wrapper d-flex justify-content-lg-between flex-wrap"
          > -->
          <div v-if="eventDatas.length === 0">
            <h3 class="text-danger">{{ errMessage }}</h3>
          </div>

          <div
            v-else
            class="event-4-u-wrapper d-flex justify-content-lg-start flex-wrap"
          >
            <EventCard
              v-for="event in eventDatas"
              :key="event.id"
              :eventImage="getEventImage(event.image_link)"
              :eventTitle="event.event_name"
              :eventDate="formatDate(event.date_of_event)"
              :eventTime="formatTime(event.time_of_event)"
              :eventAddress="event.address"
              :eventCity="event.city"
              :eventPrice="`$${getEventPrice(event.ticket)}`"
              :eventType="event.event_type"
              :eventHost="event.vendor_name"
              @click="showEventDetails(event.id)"
            />
          </div>
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useGetEventStore } from "@/store/getEvents";
import EventSearchBlock from "./EventSearchBlock.vue";
import EventCard from "./EventCard.vue";
import UpcomingEventList from "./UpcomingEventList.vue";
export default {
  name: "UpcomingEvent",
  components: { EventSearchBlock, EventCard, UpcomingEventList },
  setup() {
    const events = useGetEventStore();
    const eventDatas = ref("");
    const router = useRouter();
    const errMessage = ref("");
    const searchedEventDatas = ref("");
    const eventSearchedDatas = ref("");

    const getAllEvent = async () => {
      try {
        const response = await events.getAllEvents();

        if (response.status === 404 || !response.data || !response.data.data) {
          // Handle the case when there's a 404 error or no data available

          errMessage.value = response.data.message;
          // You might want to set default values or handle this case based on your application's logic
          return response;
        }
        // If data is available, proceed with filtering
        eventDatas.value = response.data.data.data;
      } catch (error) {
        console.log(error);
      }
    };

    const searchedEventData = (data) => {
      searchedEventDatas.value = data;
      eventSearchedDatas.value = searchedEventDatas.value;
    };

    onMounted(() => {
      getAllEvent();
    });

    const getEventImage = (eventImage) => {
      return `${process.env.VUE_APP_IMAGE_PREVIEW}${eventImage}`;
    };

    const formatDate = (inputDate) => {
      if (
        !inputDate ||
        typeof inputDate !== "string" ||
        !inputDate.includes("-")
      ) {
        return "Invalid Date";
      }
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

      const dateParts = inputDate.split("-");
      const year = parseInt(dateParts[0]);
      const month = parseInt(dateParts[1]) - 1; // Month indexes start from 0
      const day = parseInt(dateParts[2]);

      const formattedDate = new Date(year, month, day);
      const dayOfWeek = daysOfWeek[formattedDate.getDay()];
      const monthName = months[month];

      return `${dayOfWeek}, ${monthName} ${day}`;
    };

    function formatTime(inputTime) {
      if (
        !inputTime ||
        typeof inputTime !== "string" ||
        !inputTime.includes(":")
      ) {
        return "Invalid Time";
      }
      const [hours, minutes] = inputTime.split(":");
      let formattedHours = parseInt(hours);
      const period = formattedHours >= 12 ? "pm" : "am";

      // Convert hours to 12-hour clock format
      if (formattedHours > 12) {
        formattedHours -= 12;
      } else if (formattedHours === 0) {
        formattedHours = 12;
      }

      return `${formattedHours}:${minutes}${period}`;
    }

    const getEventPrice = (price) => {
      if (price.length === 0) {
        return null;
      }

      // Sort tickets based on amount in ascending order
      price.sort((a, b) => Number(a.selling_price) - Number(b.selling_price));

      // Return the ticket with the lowest amount
      return Number(price[0].selling_price).toFixed(2);
    };

    const showEventDetails = (id) => {
      router.push({
        name: "eventDetails",
        params: { id: id },
      });
    };

    return {
      eventDatas,
      getEventImage,
      formatDate,
      formatTime,
      getEventPrice,
      searchedEventData,
      showEventDetails,
      eventSearchedDatas,
      errMessage,
    };
  },
};
</script>

<style scoped>
.upcoming-event-wrapper {
  min-height: 100vh;
  background-color: #fff;
  padding-bottom: 100px;
}
.upcoming-event > h1 {
  color: #101010;
  font-family: var(--ff-roboto);
  font-size: 40px;
  font-weight: 700;
  margin: 40px 0 20px 0;
}
.event-4-u {
  margin-top: 100px;
}

.event-4-u > h1 {
  color: #101010;

  font-family: var(--ff-roboto);
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}
.event-4-u-wrapper {
  margin-top: 30px;
}

@media screen and (max-width: 576px) {
  .upcoming-event > h1 {
    font-size: 25px;
  }
}
</style>
