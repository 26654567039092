<template>
  <div class="home">
    <preloaderComp />
    <NavbarComp />
    <LandingHeaderComp :bgImage="bgImage" :headText="headText" />
    <UpcomingEvent />
    <FooterComp />
  </div>
</template>

<script>
import { onMounted } from "vue";
import preloaderComp from "@/components/Loader/preloaderComp.vue";
import NavbarComp from "@/components/NavBar/NavbarComp.vue";
import LandingHeaderComp from "@/components/Header/LandingHeaderComp.vue";
import UpcomingEvent from "@/components/Event/UpcomingEvent.vue";
import FooterComp from "@/components/FooterComp.vue";

export default {
  name: "HomeView",
  components: {
    preloaderComp,
    NavbarComp,
    LandingHeaderComp,
    UpcomingEvent,
    FooterComp,
  },
  setup() {
    const bgImage = "/img/headerBg.png";
    const headText =
      "Welcome to Afroverified, your one-stop solution for secure and verified tickets to concerts and events in Canada.";

    onMounted(() => {
      window.scrollTo(0, 0);
    });

    return { bgImage, headText };
  },
};
</script>
