<template>
  <div class="header-wrapper" :style="{ backgroundImage: `url(${bgImage})` }">
    <div class="container">
      <div class="header-block d-flex align-items-center">
        <div class="block">
          <h1>{{ headText }}</h1>
          <p>
            At Afroverified, we are passionate about celebrating cultural
            diversity and heritage. Our platform is dedicated to empowering
            individuals from all backgrounds to unlock their full potential and
            enjoy a wide range of events.
          </p>
          <div class="button-wrapper d-flex justify-content-center">
            <router-link :to="{ name: 'events' }">
              <button class="button button-lg nearby-event-btn">
                Find Nearby Events
              </button>
            </router-link>
            <router-link to="/coming-soon">
              <button class="outline-button resell-ticket-btn ms-5">
                Resell Tickets
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandingHeaderComp",
  props: ["bgImage", "headText"],
  setup() {},
};
</script>

<style scoped>
.header-block {
  min-height: 100vh;
}
.block {
  margin-top: 120px;
}
.header-block > div > h1 {
  color: #fff;
  text-align: center;
  font-family: var(--ff-roboto);
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
}

@media screen and (max-width: 992px) {
  .header-block > div > h1 {
    font-size: 36px;
  }
  .button-wrapper {
    margin-bottom: 300px;
  }
}

@media screen and (max-width: 460px) {
  .button-wrapper {
    margin-bottom: 300px;
    flex-direction: column;
  }
  .nearby-event-btn,
  .resell-ticket-btn {
    width: 100%;
  }
  .resell-ticket-btn {
    margin-left: 0 !important;
    margin-top: 20px;
    margin-bottom: 70px;
  }
}
.header-block > div > p {
  color: #efefef;
  text-align: center;
  font-family: var(--ff-roboto);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.resell-ticket-btn {
  padding: 14px 22px !important;
  background-color: transparent;
  border: 2px solid #fff;
  box-sizing: border-box;
  color: #fff;
}

.button-wrapper {
  margin-top: 24px;
  margin-bottom: 30px;
}
</style>
