<template>
  <div class="filter-block d-flex justify-content-between">
    <p
      class="filter-text"
      @click="selectTab(1)"
      :class="{ 'active-filters': currentTab === 1 }"
    >
      All
    </p>

    <p
      class="filter-text"
      @click="selectTab(2)"
      :class="{ 'active-filters': currentTab === 2 }"
    >
      Business
    </p>

    <p
      class="filter-text"
      @click="selectTab(3)"
      :class="{ 'active-filters': currentTab === 3 }"
    >
      Concerts
    </p>

    <p
      class="filter-text"
      @click="selectTab(4)"
      :class="{ 'active-filters': currentTab === 4 }"
    >
      Conference
    </p>

    <p
      class="filter-text"
      @click="selectTab(5)"
      :class="{ 'active-filters': currentTab === 5 }"
    >
      Fashion shows
    </p>

    <p
      class="filter-text"
      @click="selectTab(6)"
      :class="{ 'active-filters': currentTab === 6 }"
    >
      Festival
    </p>

    <p
      class="filter-text"
      @click="selectTab(7)"
      :class="{ 'active-filters': currentTab === 7 }"
    >
      Parties
    </p>
  </div>

  <div class="filter-icon-block" v-show="$route.path === '/events'">
    <div
      class="filter-icon"
      data-bs-toggle="modal"
      data-bs-target="#exampleModal"
    >
      <p>Filter</p>
      <FunnelIcon />
    </div>
  </div>

  <!-- FILTER EVENTS MODAL STARTS HERE -->
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="mb-3">
            <div class="row">
              <div class="col-12 col-md-2">
                <label>City</label>
              </div>
              <div class="col-12 col-md-10">
                <select class="form-control form-control-lg ms-2 shadow-none">
                  <option value="">Search City</option>
                  <option value=""></option>
                  <option value=""></option>
                </select>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <div class="row">
              <div class="col-12 col-md-2">
                <label>Date</label>
              </div>
              <div class="col-12 col-md-10">
                <input
                  type="date"
                  class="form-control form-control-lg ms-2 shadow-none"
                />
              </div>
            </div>
          </div>

          <button class="button filter-btn" data-bs-dismiss="modal">
            Filter
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- ENDS HERE -->

  <div class="show-event-card-block">
    <!-- Display All Events -->

    <div v-if="currentTab === 1">
      <!-- ALL EVENT CATEGORIES STARTS HERE -->

      <!-- <div>
        {{ eventSearchedData }}
      </div> -->
      <!-- ===========> SEARCHED EVENTS TO BE DISPLAYED HERE <=============== -->
      <div class="event-card-block-overlay" v-show="eventSearchedData">
        <!-- <div v-if="eventSearchedData.length === 0">
          <h3 class="text-danger">No Events Found.</h3>
        </div> -->

        <!-- <div v-else> -->
        <div class="d-flex justify-content-lg-start flex-wrap">
          <EventCard
            :key="event.id"
            v-for="event in eventSearchedData"
            :eventImage="getEventImage(event.image_link)"
            :eventTitle="event.event_name"
            :eventDate="formatDate(event.date_of_event)"
            :eventTime="formatTime(event.time_of_event)"
            :eventAddress="event.address"
            :eventCity="event.city"
            :eventPrice="`$${getEventPrice(event.ticket)}`"
            :eventType="event.event_type"
            :eventHost="event.vendor_name"
            @click="showEventDetails(event.uuid)"
          />
        </div>
        <!-- </div> -->
      </div>
      <!-- ==============> ENDS HERE <==================== -->

      <!-- <div>Show Data here</div> -->

      <div v-if="eventDatas.length === 0">
        <h3 class="text-danger">{{ errMessage }}</h3>
      </div>

      <div v-else>
        <div class="d-flex justify-content-lg-start flex-wrap">
          <EventCard
            v-for="event in eventDatas"
            :key="event.id"
            :eventImage="getEventImage(event.image_link)"
            :eventTitle="event.event_name"
            :eventDate="formatDate(event.date_of_event)"
            :eventTime="formatTime(event.time_of_event)"
            :eventAddress="event.address"
            :eventCity="event.city"
            :eventPrice="`$${getEventPrice(event.ticket)}`"
            :eventType="event.event_type"
            :eventHost="event.vendor_name"
            @click="showEventDetails(event.uuid)"
          />
        </div>
      </div>
    </div>

    <!-- Display Business Events -->
    <div v-if="currentTab === 2">
      <div v-if="isBusiness.length === 0">
        <h3 class="text-danger">No events in this category!</h3>
      </div>

      <div v-else>
        <div class="d-flex justify-content-lg-start flex-wrap">
          <EventCard
            v-for="event in isBusiness"
            :key="event.id"
            :eventImage="getEventImage(event.image_link)"
            :eventTitle="event.event_name"
            :eventDate="formatDate(event.date_of_event)"
            :eventTime="formatTime(event.time_of_event)"
            :eventAddress="event.address"
            :eventCity="event.city"
            :eventPrice="`$${getEventPrice(event.ticket)}`"
            :eventType="event.event_type"
            :eventHost="event.vendor_name"
            @click="showEventDetails(event.uuid)"
          />
        </div>
      </div>
      <!-- </div> -->
    </div>

    <!-- Display Concerts Events -->
    <div v-if="currentTab === 3">
      <!-- <div class="d-flex justify-content-lg-start flex-wrap"> -->
      <div v-if="isConcert.length === 0">
        <h3 class="text-danger">No events in this category!</h3>
      </div>

      <div v-else>
        <div class="d-flex justify-content-lg-start flex-wrap">
          <EventCard
            v-for="event in isConcert"
            :key="event.id"
            :eventImage="getEventImage(event.image_link)"
            :eventTitle="event.event_name"
            :eventDate="formatDate(event.date_of_event)"
            :eventTime="formatTime(event.time_of_event)"
            :eventAddress="event.address"
            :eventCity="event.city"
            :eventPrice="`$${getEventPrice(event.ticket)}`"
            :eventType="event.event_type"
            :eventHost="event.vendor_name"
            @click="showEventDetails(event.uuid)"
          />
        </div>
      </div>
      <!-- </div> -->
    </div>

    <!-- Display Conference Events -->
    <div v-if="currentTab === 4">
      <div v-if="isConference.length === 0">
        <h3 class="text-danger">No events in this category!</h3>
      </div>

      <div v-else>
        <div class="d-flex justify-content-lg-start flex-wrap">
          <EventCard
            v-for="event in isConference"
            :key="event.id"
            :eventImage="getEventImage(event.image_link)"
            :eventTitle="event.event_name"
            :eventDate="formatDate(event.date_of_event)"
            :eventTime="formatTime(event.time_of_event)"
            :eventAddress="event.address"
            :eventCity="event.city"
            :eventPrice="`$${getEventPrice(event.ticket)}`"
            :eventType="event.event_type"
            :eventHost="event.vendor_name"
            @click="showEventDetails(event.uuid)"
          />
        </div>
      </div>
    </div>

    <!-- Display Fashion Shows Events -->
    <div v-if="currentTab === 5">
      <!-- <div class="d-flex justify-content-lg-start flex-wrap"> -->
      <div v-if="isFashionShow.length === 0">
        <h3 class="text-danger">No events in this category!</h3>
      </div>

      <div v-else>
        <div class="d-flex justify-content-lg-start flex-wrap">
          <EventCard
            v-for="event in isFashionShow"
            :key="event.id"
            :eventImage="getEventImage(event.image_link)"
            :eventTitle="event.event_name"
            :eventDate="formatDate(event.date_of_event)"
            :eventTime="formatTime(event.time_of_event)"
            :eventAddress="event.address"
            :eventCity="event.city"
            :eventPrice="`$${getEventPrice(event.ticket)}`"
            :eventType="event.event_type"
            :eventHost="event.vendor_name"
            @click="showEventDetails(event.uuid)"
          />
        </div>
      </div>
      <!-- </div> -->
    </div>

    <!-- Display Festival Events -->
    <div v-if="currentTab === 6">
      <!-- <div class="d-flex justify-content-lg-start flex-wrap"> -->
      <div v-if="isFestival.length === 0">
        <h3 class="text-danger">No events in this category!</h3>
      </div>

      <div v-else>
        <div class="d-flex justify-content-lg-start flex-wrap">
          <EventCard
            v-for="event in isFestival"
            :key="event.id"
            :eventImage="getEventImage(event.image_link)"
            :eventTitle="event.event_name"
            :eventDate="formatDate(event.date_of_event)"
            :eventTime="formatTime(event.time_of_event)"
            :eventAddress="event.address"
            :eventCity="event.city"
            :eventPrice="`$${getEventPrice(event.ticket)}`"
            :eventType="event.event_type"
            :eventHost="event.vendor_name"
            @click="showEventDetails(event.uuid)"
          />
        </div>
      </div>
      <!-- </div> -->
    </div>

    <!-- Display Sport Events -->
    <div v-if="currentTab === 7">
      <!-- <div class="d-flex justify-content-lg-start flex-wrap"> -->
      <div v-if="isParties.length === 0">
        <h3 class="text-danger">No events in this category!</h3>
      </div>

      <div v-else>
        <div class="d-flex justify-content-lg-start flex-wrap">
          <EventCard
            v-for="event in isParties"
            :key="event.id"
            :eventImage="getEventImage(event.image_link)"
            :eventTitle="event.event_name"
            :eventDate="formatDate(event.date_of_event)"
            :eventTime="formatTime(event.time_of_event)"
            :eventAddress="event.address"
            :eventCity="event.city"
            :eventPrice="`$${getEventPrice(event.ticket)}`"
            :eventType="event.event_type"
            :eventHost="event.vendor_name"
            @click="showEventDetails(event.uuid)"
          />
        </div>
      </div>
      <!-- </div> -->
    </div>

    <div class="loader-overlay" v-if="isLoader">
      <img src="./rolling.gif" alt="" />
    </div>
  </div>

  <!-- Will later implement this later in the future -->
  <!-- <div class="row">
    <div class="col-12 col-md-4 mx-auto">
      <button class="button button-lg w-100">See more events</button>
    </div>
  </div> -->

  <!-- ======================================================= -->
  <!-- ============= PAGINATION STARTS HERE ================ -->
  <!-- ======================================================= -->
  <ul class="mt-3 d-flex pagination-wrapper justify-content-center">
    <!-- Previous button -->
    <li
      class="previous-btn pagination fw-bold"
      @click="goToPreviousPage"
      :disabled="currentPage === 1"
      :class="{ disabled: currentPage === 1 }"
    >
      <i class="bi bi-chevron-left"></i>
    </li>
    <div class="number-wrapper d-flex">
      <li
        class="pagination"
        v-for="page in totalPages"
        :key="page"
        :class="{ active: currentPage === page }"
        @click="goToPage(page)"
      >
        {{ page }}
      </li>
    </div>
    <!-- Next button -->
    <li
      class="next-btn pagination fw-bold"
      @click="goToNextPage"
      :disabled="currentPage === totalPages"
      :class="{ disabled: currentPage === totalPages }"
    >
      <i class="bi bi-chevron-right"></i>
    </li>
  </ul>

  <!-- ======================================================= -->
  <!-- =======================   ENDS HERE =================== -->
  <!-- ======================================================= -->
</template>

<script>
import axios from "axios";
import { ref, onMounted } from "vue";
import FunnelIcon from "./Icons/FunnelIcon.vue";
import EventCard from "./EventCard.vue";
import { useGetEventStore } from "@/store/getEvents";
import { useRouter } from "vue-router";
import { api } from "@/api/utils";
export default {
  name: "UpcomingEventList",
  props: ["eventSearchedData"],
  components: { FunnelIcon, EventCard },
  setup() {
    const router = useRouter();
    const events = useGetEventStore();
    const currentTab = ref(1);
    const eventDatas = ref([]);
    const vendorName = ref("");
    const filteredEvents = ref("");
    const searchedEventDatas = ref([]);
    const isFashionShow = ref("");
    const isBusiness = ref("");
    const isConcert = ref("");
    const isConference = ref("");
    const isFestival = ref("");
    const isParties = ref("");
    const errMessage = ref("");
    const isLoader = ref(false);

    // ############# PAGINATION REACTIVE DATA
    const currentPage = ref(1);
    const totalPages = ref(1);

    // Handle Selected Tab
    const selectTab = (selectedTab) => {
      currentTab.value = selectedTab;
    };

    const getAllEvent = async () => {
      isLoader.value = true;
      try {
        const response = await events.getAllEvents();

        if (response.status === 404 || !response.data || !response.data.data) {
          // Handle the case when there's a 404 error or no data available
          // For example:
          // console.error("No data available or 404 error");
          errMessage.value = response.data.message;
          // You might want to set default values or handle this case based on your application's logic
          return response;
        }

        // If data is available, proceed with filtering
        eventDatas.value = response.data.data.data;

        isFashionShow.value = eventDatas.value.filter(
          (event) => event.category === "fashion show"
        );
        isBusiness.value = eventDatas.value.filter(
          (event) => event.category === "business"
        );
        isConcert.value = eventDatas.value.filter(
          (event) => event.category === "concert"
        );
        isConference.value = eventDatas.value.filter(
          (event) => event.category === "conference"
        );
        isFestival.value = eventDatas.value.filter(
          (event) => event.category === "festival"
        );
        isParties.value = eventDatas.value.filter(
          (event) => event.category === "parties"
        );
      } catch (error) {
        // Handle other types of errors if needed
        console.error("Error occurred:", error);
        // You might want to show an error message or handle this case based on your application's logic
      } finally {
        isLoader.value = false;
      }
    };

    const searchedEventData = (data) => {
      console.log("Searched Event:" + data);
      searchedEventDatas.value = data;
    };

    // searchedEventData();

    const getEventImage = (eventImage) => {
      return `${process.env.VUE_APP_IMAGE_PREVIEW}${eventImage}`;
    };

    const formatDate = (inputDate) => {
      if (
        !inputDate ||
        typeof inputDate !== "string" ||
        !inputDate.includes("-")
      ) {
        return "Invalid Date";
      }
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

      const dateParts = inputDate.split("-");
      const year = parseInt(dateParts[0]);
      const month = parseInt(dateParts[1]) - 1; // Month indexes start from 0
      const day = parseInt(dateParts[2]);

      const formattedDate = new Date(year, month, day);
      const dayOfWeek = daysOfWeek[formattedDate.getDay()];
      const monthName = months[month];

      return `${dayOfWeek}, ${monthName} ${day}`;
    };

    function formatTime(inputTime) {
      if (
        !inputTime ||
        typeof inputTime !== "string" ||
        !inputTime.includes(":")
      ) {
        return "Invalid Time";
      }
      const [hours, minutes] = inputTime.split(":");
      let formattedHours = parseInt(hours);
      const period = formattedHours >= 12 ? "pm" : "am";

      // Convert hours to 12-hour clock format
      if (formattedHours > 12) {
        formattedHours -= 12;
      } else if (formattedHours === 0) {
        formattedHours = 12;
      }

      return `${formattedHours}:${minutes}${period}`;
    }

    const getEventPrice = (price) => {
      if (price.length === 0) {
        return null;
      }

      // Sort tickets based on amount in ascending order
      price.sort((a, b) => Number(a.selling_price) - Number(b.selling_price));

      // Return the ticket with the lowest amount
      return Number(price[0].selling_price).toFixed(2);
    };

    const showEventDetails = (uuid) => {
      router.push({
        name: "eventDetails",
        params: { id: uuid },
      });
    };

    // ################### PAGINATION LOGIC AREA #######################
    // Event handlers
    const goToPage = (page) => {
      currentPage.value = page;
      sessionStorage.setItem("CurrentPage", currentPage.value);
      // console.log("Page Value", currentPage.value);
      fetchData(page);
    };

    const goToPreviousPage = () => {
      if (currentPage.value > 1) {
        goToPage(currentPage.value - 1);
      }
    };

    const goToNextPage = () => {
      if (currentPage.value < totalPages.value) {
        goToPage(currentPage.value + 1);
      }
    };

    const fetchData = async () => {
      // Implement your logic to fetch data from the endpoint based on the selected page
      try {
        const response = await axios.get(
          `${api}/get.all?page=${currentPage.value}`
        );
        const data = response.data.data;
        // console.log("Da data", data);
        // console.log("Response", data.meta.last_page);
        eventDatas.value = data.data;
        totalPages.value = data.meta.last_page;
      } catch (error) {
        return error;
      }
    };

    onMounted(() => {
      getAllEvent();
      fetchData();
      // profileData();
    });

    return {
      eventDatas,
      filteredEvents,
      selectTab,
      currentTab,
      vendorName,
      currentPage,
      totalPages,
      formatDate,
      formatTime,
      showEventDetails,
      getAllEvent,
      getEventImage,
      getEventPrice,
      isFashionShow,
      isBusiness,
      isConcert,
      isConference,
      isFestival,
      isParties,
      searchedEventData,
      searchedEventDatas,
      errMessage,
      isLoader,
      goToPage,
      goToPreviousPage,
      goToNextPage,
      // fetchData,
    };
  },
};
</script>

<style scoped>
.filter-block {
  margin-top: 40px;
}
.filter-text {
  color: #525252;
  font-family: var(--ff-poppins);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  flex-shrink: 0;
  flex-wrap: nowrap;
  cursor: pointer;
}

.filter-icon-block,
.filter-icon {
  display: flex;
  cursor: pointer;
}
.filter-icon-block {
  justify-content: flex-end;
  margin-top: 20px;
}
.filter-icon {
  align-items: flex-start;
}
.filter-icon-block p {
  color: var(--lust);
  font-family: var(--ff-poppins);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin: 0;
  line-height: normal;
}
.modal-body {
  padding: 30px !important;
}
.modal-body label {
  color: #202020;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.modal-body .filter-btn {
  padding: 16px 24px;
  margin-top: 30px;
  width: 100%;
}

.show-event-card-block {
  margin: 40px 0;
  position: relative;
}

.loader-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  top: 0;
  left: 0;
  display: flex;
  z-index: 3;
  align-items: center;
  justify-content: center;
  background-color: #ededed;
}
.loader-overlay img {
  width: 100px;
  height: 100px;
}

.event-card-block-overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.87);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  border-radius: 5px;
}

.pagination {
  padding: 10px 12px;
  border-radius: 5px;
  border: 2px solid #e82210;
  background: #ffe7e4;
  margin-right: 10px;
  color: #555;
  font-family: Open Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}
.active {
  color: #fff !important;
  background-color: #e82210;
  font-weight: bold;
}
.active-filters {
  color: red !important;
  border-bottom: 2px solid red;
}
.disabled {
  cursor: not-allowed;
}

@media screen and (max-width: 576px) {
  .filter-text {
    font-size: 20px;
  }
}

@media screen and (max-width: 992px) {
  .filter-block {
    overflow-x: scroll;
  }
  ::-webkit-scrollbar {
    width: 11px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f8f8f8;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #b4b3b3;
  }
  .filter-text {
    font-size: 20px;
    margin-right: 50px;
    margin-bottom: 20px;
  }
}
</style>
