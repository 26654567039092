import { defineStore } from "pinia";
import { ref, computed } from "vue";
import axios from "axios";
import { api } from "../api/utils";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";

export const useAuthStore = defineStore(
  "auth",
  () => {
    const toast = useToast();
    const user = ref({});
    const token = ref("");

    const userData = computed(() => user.value);
    const userId = computed(() => user.value.id);
    const userToken = computed(() => token.value);
    const isLoggedIn = computed(() => {
      return token.value !== "" ? true : false;
    });

    const router = useRouter();

    /**
     * Registers a user by sending a POST request to the server and route to the email sent page.
     *
     * @param {Object} data - The data of the user to be registered.
     * @return {Promise} A promise that resolves with the response from the server.
     */
    const register = async (data) => {
      try {
        const timestamp = Date.now();
        const response = await axios.post(
          `${api}/auth/register?=_${timestamp}`,
          data
        );

        if (response.data.status === "Success") {
          router.push({ name: "emailsent", state: { email: data.email } });
        }
        return response;
      } catch (error) {
        return error.response;
      }
    };

    /**
     * Sign in the user with the given data.
     *
     * @param {Object} data - The data needed for signing in.
     * @return {Promise} A promise that resolves with the response data.
     */
    const signin = async (data) => {
      try {
        const response = await axios.post(`${api}/auth/login`, data);
        user.value = response.data.data.data;
        token.value = response.data.data.token;

        return response;
      } catch (error) {
        toast.error(error.response.data.message, { timeout: 2000 });
        window.location.reload();
      }
    };

    /**
     * Resets the request by sending a POST request to the server with the provided data.
     *
     * @param {Object} data - The data to be sent in the request.
     * @return {Promise} A promise that resolves with the response from the server.
     */
    const resetRequest = async (data) => {
      try {
        const response = await axios.post(`${api}/auth/forgot.password`, data);

        if (response.data.status === "Success") {
          router.push({ name: "resetemail", state: { email: data.email } });
        }
        return response;
      } catch (error) {
        return error.response;
      }
    };

    const resetPassword = async (data) => {
      try {
        const response = await axios.put(`${api}/auth/update.password`, data);

        return response;
      } catch (error) {
        return error.response.data.message;
      }
    };

    const logout = async () => {
      try {
        const response = await axios.get(`${api}/auth/logout`, {
          headers: {
            Authorization: `Bearer ${userToken.value}`,
            "user-x-key": userId.value,
          },
        });
        return response;
      } catch (error) {
        return error;
      }
    };

    const reconfirmEmail = async (data) => {
      try {
        const response = await axios.post(`${api}/auth/reconfirm.email`, data);
        return response;
      } catch (error) {
        return error.response;
      }
    };

    return {
      user,
      token,
      userData,
      userId,
      userToken,
      isLoggedIn,
      register,
      signin,
      resetRequest,
      resetPassword,
      reconfirmEmail,
      logout,
    };
  },
  {
    persist: {
      paths: ["user", "token"],
    },
    beforeRestore: (ctx) => {
      return ctx;
      // console.log(`about to restore '${ctx.store.$id}'`);
    },
  }
);
