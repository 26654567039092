import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "@/store/auth";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "AfroVerified | Home",
    },
  },
  {
    path: "/aboutus",
    name: "aboutus",
    component: () => import("@/views/AboutUs.vue"),
    meta: {
      title: "AfroVerified | About Us",
    },
  },
  {
    path: "/blog",
    name: "blog",
    component: () => import("@/views/BlogPage.vue"),
    meta: {
      title: "AfroVerified | Blog",
    },
  },
  {
    path: "/blog-details/:id",
    name: "blogDetails",
    component: () => import("@/views/BlogDetails.vue"),
    meta: {
      title: "AfroVerified | Blog Details",
    },
  },
  {
    path: "/signin",
    name: "signin",
    component: () => import("@/views/SignIn.vue"),
    meta: {
      title: "AfroVerified | Sign In",
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/RegisterPage.vue"),
    meta: {
      title: "AfroVerified | Register",
    },
  },
  {
    path: "/emailsent",
    name: "emailsent",
    component: () => import("@/views/EmailSent.vue"),
    meta: {
      title: "AfroVerified | Email Sent",
    },
  },
  {
    path: "/email-status",
    name: "emailStatus",
    component: () => import("@/views/EmailSuccessPage.vue"),
    meta: {
      title: "AfroVerified | Email Status",
    },
  },
  {
    path: "/email-successful",
    name: "emailSuccessful",
    component: () => import("@/views/EmailSuccessful.vue"),
    meta: {
      title: "AfroVerified | Email Successful",
    },
  },
  {
    path: "/resetemail",
    name: "resetemail",
    component: () => import("@/views/ResetEmail.vue"),
    meta: {
      title: "AfroVerified | Reset Email",
    },
  },
  {
    path: "/forgotpassword",
    name: "forgotpassword",
    component: () => import("@/views/ForgotPassword.vue"),
    meta: {
      title: "AfroVerified | Forgot Password",
    },
  },
  {
    path: "/reset-password",
    name: "resetPassword",
    component: () => import("@/views/ResetPassword.vue"),
    meta: {
      title: "AfroVerified | Reset Password",
    },
    // props: (route) => ({ id: route.params.id }),
  },
  {
    path: "/events",
    name: "events",
    component: () => import("@/views/EventsPage.vue"),
    meta: {
      title: "AfroVerified | Events",
    },
  },
  {
    // I will later add a params here
    path: "/events-details/:id",
    name: "eventDetails",
    component: () => import("@/views/EventDetails.vue"),
    meta: {
      title: "AfroVerified | EventDetails",
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/Dashboard/DashboardPage.vue"),
    meta: {
      title: "AfroVerified | Dashboard",
      requireAuth: true,
    },
    redirect: { name: "general" },
    children: [
      {
        path: "/general",
        name: "general",
        component: () =>
          import("@/components/Dashboard/General/GeneralComp.vue"),
        meta: {
          title: "Afroverified | General",
        },
      },
      {
        path: "/listings",
        name: "listings",
        component: () =>
          import("@/components/Dashboard/Listings/ListingComp.vue"),
        meta: {
          title: "Afroverified | General",
        },
        redirect: { name: "publish" },
        children: [
          {
            path: "/publish",
            name: "publish",
            component: () =>
              import("@/components/Dashboard/Listings/PublishCard.vue"),
            meta: {
              title: "Afroverified | Publish",
            },
          },
          {
            path: "/unpublish",
            name: "unpublish",
            component: () =>
              import("@/components/Dashboard/Listings/UnpublishCard.vue"),
            meta: {
              title: "Afroverified | Unpublish",
            },
          },
        ],
      },
      {
        path: "/create-event",
        name: "createEvent",
        component: () =>
          import("@/components/Dashboard/CreateEvent/CreateEventComp.vue"),
        meta: {
          title: "Afroverified | Create EVent",
        },
        redirect: { name: "basic" },
        children: [
          {
            path: "/basic",
            name: "basic",
            component: () =>
              import("@/components/Dashboard/CreateEvent/BasicComp.vue"),
          },
          {
            path: "/ticket",
            name: "ticket",
            component: () =>
              import("@/components/Dashboard/CreateEvent/TicketComp.vue"),
          },
          {
            path: "/calendar",
            name: "calendar",
            component: () =>
              import("@/components/Dashboard/CreateEvent/CalendarComp.vue"),
          },
          {
            path: "/coupon",
            name: "coupon",
            component: () =>
              import("@/components/Dashboard/CreateEvent/CouponComp.vue"),
          },
          {
            path: "/staff-member",
            name: "staffMember",
            component: () =>
              import("@/components/Dashboard/CreateEvent/StaffMemberComp.vue"),
          },
        ],
      },
      {
        path: "/edit-event",
        name: "editEvent",
        component: () => import("@/views/Dashboard/EditPage.vue"),
        meta: {
          title: "Afroverified | Edit Event",
        },
        redirect: { name: "editBasic" },
        children: [
          {
            path: "/edit-basic",
            name: "editBasic",
            component: () =>
              import("@/components/Dashboard/EditEvent/EditBasicComp.vue"),
            meta: {
              title: "Afroverified | Edit Event",
            },
          },
          {
            path: "/edit-ticket",
            name: "editTicket",
            component: () =>
              import("@/components/Dashboard/EditEvent/EditTicketComp.vue"),
            meta: {
              title: "Afroverified | Edit Event",
            },
          },
          {
            path: "/edit-calendar",
            name: "editCalendar",
            component: () =>
              import("@/components/Dashboard/EditEvent/EditCalendarComp.vue"),
            meta: {
              title: "Afroverified | Edit Event",
            },
          },
          {
            path: "/edit-coupon",
            name: "editCoupon",
            component: () =>
              import("@/components/Dashboard/EditEvent/EditCouponComp.vue"),
            meta: {
              title: "Afroverified | Edit Event",
            },
          },
          {
            path: "/edit-staff-member",
            name: "editStaffMember",
            component: () =>
              import(
                "@/components/Dashboard/EditEvent/EditStaffMemberComp.vue"
              ),
            meta: {
              title: "Afroverified | Edit Event",
            },
          },
        ],
      },
      {
        path: "/packages",
        name: "packages",
        component: () =>
          import("@/components/Dashboard/Packages/PackagesComp.vue"),
        meta: {
          title: "Afroverified | Packages",
        },
      },
      {
        path: "/bookings",
        name: "bookings",
        component: () =>
          import("@/components/Dashboard/Bookings/BookingsComp.vue"),
        meta: {
          title: "Afroverified | Bookings",
        },
      },
      {
        path: "/account",
        name: "account",
        component: () => import("@/views/Dashboard/AccountLayout.vue"),
        meta: {
          title: "AfroVerified | Account",
        },
        redirect: { name: "profile" },
        children: [
          {
            path: "",
            name: "profile",
            component: () =>
              import("@/components/Dashboard/Account/ProfilePage.vue"),
          },
          {
            path: "/account/social",
            name: "social",
            component: () =>
              import("@/components/Dashboard/Account/SocialPage.vue"),
          },
          {
            path: "/account/edit-social",
            name: "edit-social",
            component: () =>
              import("@/components/Dashboard/Account/EditSocialPage.vue"),
          },
          {
            path: "/account/change-password",
            name: "change-password",
            component: () =>
              import("@/components/Dashboard/Account/ChangePassword.vue"),
          },
          {
            path: "/account/payout-method",
            name: "payout-method",
            component: () =>
              import("@/components/Dashboard/Account/PayoutMethod.vue"),
          },
        ],
      },
      // {
      //   path: "/stripe-success",
      //   name: "stripeSuccess",
      //   component: () => import("@/views/StripeSuccessPage.vue"),
      //   meta: {
      //     title: "Afroverified | Success",
      //   },
      // },
      // {
      //   path: "/payment",
      //   name: "payment",
      //   component: () => import("@/views/paymentPage.vue"),
      //   meta: {
      //     title: "Afroverified | Payment Page",
      //   },
      // },
    ],
  },
  {
    path: "/user-dashboard",
    name: "user-dashboard",
    component: () => import("@/components/Dashboard/CustomerLayout.vue"),
    meta: {
      title: "Afroverified | My Bookings",
    },
    redirect: { name: "customer-bookings" },
    children: [
      {
        path: "/customer-bookings",
        name: "customer-bookings",
        component: () => import("@/views/CustomerPage.vue"),
        meta: {
          title: "Afroverified | My Bookings",
        },
      },
      {
        path: "/user-profile",
        name: "user-profile",
        component: () =>
          import("@/components/Dashboard/Account/UserProfile.vue"),
      },
    ],
  },
  {
    path: "/admin",
    name: "adminPage",
    component: () => import("@/views/Admin/AdminPage.vue"),
    meta: {
      title: "Afroverified | Admin",
    },
    redirect: { name: "allEvent" },
    children: [
      {
        path: "/all-event",
        name: "allEvent",
        component: () => import("@/components/Admin/AllEvent.vue"),
        meta: {
          title: "Afroverified | Event",
        },
      },
      {
        path: "/ticket-sold",
        name: "ticketSold",
        component: () => import("@/components/Admin/TicketSold.vue"),
        meta: {
          title: "Afroverified | Ticket Sold",
        },
      },
      {
        path: "/redeemed-tickets",
        name: "redeemedTickets",
        component: () => import("@/components/Admin/RedeemedTickets.vue"),
        meta: {
          title: "Afroverified | Redeemed Tickets",
        },
      },
    ],
  },
  {
    path: "/stripe-success",
    name: "stripeSuccess",
    component: () => import("@/views/StripeSuccessPage.vue"),
    meta: {
      title: "Afroverified | Success",
    },
  },
  {
    path: "/payment",
    name: "payment",
    component: () => import("@/views/paymentPage.vue"),
    meta: {
      title: "Afroverified | Payment Page",
    },
  },
  {
    path: "/payment-successful",
    name: "paymentSuccessful",
    component: () => import("@/views/PaymentSuccessful.vue"),
    meta: {
      title: "Afroverified | Payment Successful",
    },
  },
  {
    path: "/payment-not-successful",
    name: "paymentNotSuccessful",
    component: () => import("@/views/PaymentNotSuccessful.vue"),
    meta: {
      title: "Afroverified | Payment Not Successful",
    },
  },
  {
    path: "/coming-soon",
    name: "comingSoon",
    component: () => import("@/views/ComingSoon.vue"),
    meta: {
      title: "Afroverified | Payment Successful",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "notFound",
    component: () => import("@/views/NotFoundPage.vue"),
    meta: {
      title: "Page not Found",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0, left: 0 };
  },
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  next();
});

router.beforeEach((to) => {
  // router.beforeEach(() => {
  // const auth = JSON.parse(localStorage.getItem("auth"));
  const auth = useAuthStore();
  if (to.name === "signin" && auth.token) {
    if (auth.user.type === "vendor") {
      return { name: "dashboard" };
    }
    return { name: "user-dashboard" };
  }
  if (to.meta.requireAuth && !auth.userToken) {
    return { name: "signin" };
  }
});

export default router;
