import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import "@/assets/css/style.css";
import { useAuthStore } from "./store/auth";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import PrimeVue from "primevue/config";
import "primevue/resources/themes/aura-light-green/theme.css";
// import ToastService from "primevue/toastservice";
// import Toast from "primevue/toast";
// import { useToast } from "primevue/usetoast";

// prime vue component starts here

import Knob from "primevue/knob";

// import Paginate from "vuejs-paginate-next";
import {
  DynamicTab,
  DynamicTabContent,
  DynamicTabSettings,
} from "vue3-dynamic-tabs";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

createApp(App)
  .component("dynamic-tab", DynamicTab)
  .component("dynamic-tab-content", DynamicTabContent)
  .component("dynamic-tab-settings", DynamicTabSettings)
  .component("Knob", Knob)
  .component("Toast", Toast)
  .use(pinia)
  .use(router)
  .use(Toast)
  .use(PrimeVue)
  // .use(Paginate)
  .mount("#app");

/* eslint-disable */
const auth = useAuthStore();
