import { defineStore } from "pinia";
import { ref } from "vue";
import { api } from "@/api/utils";
import { useAuthStore } from "./auth";
import { useToast } from "vue-toastification";
import axios from "axios";

export const useGetEventStore = defineStore(
  "getEvents",
  () => {
    const toast = useToast();
    // const allData = ref("");
    const publishedCard = ref("");
    const auth = useAuthStore();
    // const searchEvent = ref("");
    const currentEventId = ref("");

    // GET ALL EVENTS
    const getAllEvents = async () => {
      try {
        const response = await axios.get(`${api}/get.all`);
        // const allEventsData = response.data.data;
        // sessionStorage.setItem("publicEventsData", allEventsData);
        // allData.value = response.data.data;
        return response;
      } catch (error) {
        return error.response;
      }
    };

    const getAllVendorEvents = async () => {
      try {
        const response = await axios.get(`${api}/events/get`, {
          headers: {
            Authorization: `Bearer ${auth.userToken}`,
            "user-x-key": auth.userId,
          },
        });

        publishedCard.value = response.data.data;
        localStorage.setItem("allVendorEventsData", publishedCard.value);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
        // return error.response;
      }
    };

    const getAllBlogPost = async () => {
      try {
        const response = await axios.get(`${api}/blog/get.all`, {
          headers: {
            Authorization: `Bearer ${auth.userToken}`,
            "user-x-key": auth.userId,
          },
        });
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
        // return error.response;
      }
    };

    const unPublish = async (data) => {
      try {
        const response = await axios.put(`${api}/events/update`, data, {
          headers: {
            Authorization: `Bearer ${auth.userToken}`,
            "user-x-key": auth.userId,
          },
        });
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
        // return error.response;
      }
    };

    const updateEvent = async (data) => {
      try {
        const response = await axios.put(`${api}/events/update`, data, {
          headers: {
            Authorization: `Bearer ${auth.userToken}`,
            "user-x-key": auth.userId,
          },
        });
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
        // return error.response;
      }
    };

    const updateCalendar = async (data) => {
      try {
        const response = await axios.put(`${api}/events/update`, data, {
          headers: {
            Authorization: `Bearer ${auth.userToken}`,
            "user-x-key": auth.userId,
          },
        });
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
        // return error.response;
      }
    };

    const searchEvent = async (data) => {
      try {
        const response = await axios.get(`${api}/search/${data}`, {
          headers: {
            Authorization: `Bearer ${auth.userToken}`,
            "user-x-key": auth.userId,
          },
        });
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
        // return error.response;
      }
    };

    const searchEventHome = async (data) => {
      try {
        const response = await axios.post(`${api}/search`, data, {
          headers: {
            Authorization: `Bearer ${auth.userToken}`,
            "user-x-key": auth.userId,
          },
        });
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
        // return error.response;
      }
    };

    const publishedEventData = async () => {
      try {
        const response = await axios.get(`${api}/events/status/published`, {
          headers: {
            Authorization: `Bearer ${auth.userToken}`,
            "user-x-key": auth.userId,
          },
        });
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
        // return error.response;
      }
    };

    const paginatedPublishedEvent = async (page) => {
      try {
        const response = await axios.get(
          `${api}/events/status/published?page=${page}`,
          {
            headers: {
              Authorization: `Bearer ${auth.userToken}`,
              "user-x-key": auth.userId,
            },
          }
        );
        return response;
      } catch (error) {
        return error.response;
      }
    };

    const unPublishedEventData = async () => {
      try {
        const response = await axios.get(`${api}/events/status/unpublished`, {
          headers: {
            Authorization: `Bearer ${auth.userToken}`,
            "user-x-key": auth.userId,
          },
        });
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
        // return error.response;
      }
    };

    const paginatedUnpublishedEvent = async (page) => {
      try {
        const response = await axios.get(
          `${api}/events/status/unpublished?page=${page}`,
          {
            headers: {
              Authorization: `Bearer ${auth.userToken}`,
              "user-x-key": auth.userId,
            },
          }
        );
        return response;
      } catch (error) {
        return error.response;
      }
    };

    const updateTicket = async (data) => {
      try {
        const response = await axios.put(`${api}/tickets/update`, data, {
          headers: {
            Authorization: `Bearer ${auth.userToken}`,
            "user-x-key": auth.userId,
          },
        });
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.reload();
        } else if (error.response.status === 402) {
          toast.error(
            "Sorry you cannot update paid events that already has bookings",
            { timeout: 2000 }
          );
        }
        // return error.response;
      }
    };

    const updateCoupon = async (data) => {
      try {
        const response = await axios.put(`${api}/coupons/update`, data, {
          headers: {
            Authorization: `Bearer ${auth.userToken}`,
            "user-x-key": auth.userId,
          },
        });
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
        // return error.response;
      }
    };

    const updateStaffMember = async (data) => {
      try {
        const response = await axios.put(`${api}/staff.member/update`, data, {
          headers: {
            Authorization: `Bearer ${auth.userToken}`,
            "user-x-key": auth.userId,
          },
        });
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
        // return error.response;
      }
    };

    const getPublicEvent = async (data) => {
      try {
        const response = await axios.get(`${api}/get.all/${data}`);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
        // return error.response;
      }
    };

    const publish = async (data) => {
      try {
        const response = await axios.put(`${api}/events/update`, data, {
          headers: {
            Authorization: `Bearer ${auth.userToken}`,
            "user-x-key": auth.userId,
          },
        });
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
        // return error.response;
      }
    };

    const validateCoupons = async (data) => {
      try {
        const response = await axios.post(`${api}/validate.coupons`, data, {
          headers: {
            Authorization: `Bearer ${auth.userToken}`,
            "user-x-key": auth.userId,
          },
        });
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
        // return error.response;
      }
    };

    const makeReservations = async (data) => {
      try {
        const response = await axios.post(`${api}/make.reservation`, data, {
          headers: {
            Authorization: `Bearer ${auth.userToken}`,
            "user-x-key": auth.userId,
          },
        });
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
        // return error.response;
      }
    };

    // STRIPE PAYMENT CONFIRMATION END POINT
    const paymentStatus = async (data) => {
      try {
        const response = await axios.post(`${api}/stripe.callback`, data, {
          headers: {
            Authorization: `Bearer ${auth.userToken}`,
            "user-x-key": auth.userId,
          },
        });
        return response;
      } catch (error) {
        // if (error.response.status === 401) {
        //   localStorage.clear();
        //   window.location.reload();
        // }
        return error.response;
      }
    };

    const emailConfirmation = async (data) => {
      try {
        const response = await axios.get(`${api}/email.confirmation/${data}`);
        return response;
      } catch (error) {
        return error.response;
      }
    };

    const getTotalEventDetails = async () => {
      try {
        const response = await axios.get(`${api}/history/vendor.general`, {
          headers: {
            Authorization: `Bearer ${auth.userToken}`,
            "user-x-key": auth.userId,
          },
        });
        return response;
      } catch (error) {
        return error.response;
      }
    };

    return {
      getAllEvents,
      getAllVendorEvents,
      getPublicEvent,
      getAllBlogPost,
      // allData,
      publishedCard,
      searchEvent,
      searchEventHome,
      // searchEvents,
      unPublish,
      publishedEventData,
      paginatedPublishedEvent,
      unPublishedEventData,
      paginatedUnpublishedEvent,
      updateEvent,
      updateTicket,
      updateCalendar,
      updateCoupon,
      updateStaffMember,
      publish,
      makeReservations,
      validateCoupons,
      // to store current event ID
      currentEventId,
      paymentStatus,
      emailConfirmation,
      getTotalEventDetails,
    };
  },
  {
    persist: {
      paths: ["allData", "publishedCard", "", "currentEventId"],
    },
  }
);
