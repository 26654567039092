<template>
  <footer>
    <div class="container">
      <div class="row footer-block">
        <div class="col-12 col-lg-3 mb-5">
          <router-link to="/">
            <NavBrand />
          </router-link>

          <p class="text-white footer-text">
            Welcome to Afroverified, your one-stop solution for secure and
            verified tickets to concerts and events in Canada
          </p>

          <div class="social-links d-flex">
            <a href="https://x.com/afroverifled?s=21"
              ><i class="bi bi-twitter text-white"></i
            ></a>
            <a
              href="https://instagram.com/afroverified?igshid=NzZlODBkYWE4Ng%3D%3D&utm_source=qr"
              class="ms-3"
              ><i class="bi bi-instagram text-white"></i
            ></a>
          </div>
        </div>
        <div class="col-12 col-lg-3 mb-5 d-lg-flex justify-content-md-center">
          <div>
            <h3>Quick Links</h3>
            <ul class="p-0">
              <li class="mb-3">
                <router-link
                  :to="{ name: 'aboutus' }"
                  class="footer-link text-white"
                  >About</router-link
                >
              </li>
              <li class="mb-3">
                <router-link
                  :to="{ name: 'blog' }"
                  class="footer-link text-white"
                  >Blog</router-link
                >
              </li>
              <li class="mb-3">
                <router-link to="" class="footer-link text-white"
                  >Submit event
                </router-link>
              </li>
              <li class="mb-3">
                <router-link to="" class="footer-link text-white"
                  >Resell Ticket</router-link
                >
              </li>
              <li class="mb-3">
                <a href="" class="footer-link text-white" @click="openPDF"
                  >Privacy</a
                >
              </li>
              <li class="mb-3">
                <a href="" class="footer-link text-white">Terms</a>
              </li>
              <!-- <li class="mb-3">
                <router-link to="/privacy" class="footer-link text-white"
                  >Privacy</router-link
                >
              </li> -->
            </ul>
          </div>
        </div>
        <div class="col-12 col-lg-3 mb-5">
          <h3>Contact us</h3>

          <div class="contact-block">
            <p class="text-white">
              <i class="bi bi-envelope"></i>
              <a href="mailto:afroverifiedtickets@gmail.com" class="ms-2"
                >afroverifiedtickets@gmail.com</a
              >
            </p>
            <p class="text-white">
              <i class="bi bi-geo-alt"></i>
              <span class="ms-2"
                >PO BOX 99900 HH 909 741 STN D SCARBOROUGH ON M1P 0E3</span
              >
            </p>
            <p class="text-white">
              <i class="bi bi-telephone"></i>
              <a href="tel:+16478319679" class="ms-2">+1 (647) 831-9679</a>
            </p>
          </div>
        </div>
        <div class="col-12 col-lg-3 mb-5 google-appstore">
          <div>
            <router-link to="#">
              <img src="../../public/img/googleplay.png" alt="" />
            </router-link>
          </div>
          <span>OR</span>
          <div>
            <router-link to="#">
              <img src="../../public/img/appstore.png" alt="" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import NavBrand from "./NavBar/NavBrand.vue";
export default {
  name: "FooterComp",
  components: { NavBrand },
  setup() {
    const openPDF = () => {
      // const pdfPath = "../resources/AfroVerifiedPrivacy.pdf";
      // let newWindow;
      window.open("/resources/privacy.pdf", "_blank");
    };

    return { openPDF };
  },
};
</script>

<style scoped>
footer {
  background-color: var(--blackie);
  padding: 70px 0;
}
footer h3 {
  color: #fff;
  font-family: var(--ff-roboto);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 24px;
}
.footer-text {
  font-family: var(--ff-poppins);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin: 16px 0;
}
.bi-twitter,
.bi-instagram {
  font-size: 24px;
}
.footer-link {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.contact-block > p {
  font-family: var(--ff-poppins);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 16px;
}
.contact-block a {
  color: #fff;
}
.google-appstore span {
  color: #fff;
  font-family: var(--ff-roboto);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  display: block;
  margin: 24px 0;
}
</style>
