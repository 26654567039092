import { defineStore } from "pinia";
// import { ref } from "vue";
import { api } from "@/api/utils";
import { useAuthStore } from "./auth";
import axios from "axios";

export const useCreateEventStore = defineStore(
  "createEvent",
  () => {
    const auth = useAuthStore();
    // event id from createEvent action

    // CREATE EVENTS
    const createEvent = async (data) => {
      try {
        const response = await axios.post(`${api}/events/create`, data, {
          headers: {
            Authorization: `Bearer ${auth.userToken}`,
            "user-x-key": auth.userId,
          },
        });
        // Save Event Id to Session storage when one is created
        sessionStorage.setItem("createdEventId", response.data.data.event_id);
        return response;
      } catch (error) {
        return error.response;
      }
    };

    // GET CATEGORIES
    const getCategories = async () => {
      try {
        const response = await axios.get(`${api}/events/categories`, {
          headers: {
            Authorization: `Bearer ${auth.userToken}`,
            "user-x-key": auth.userId,
          },
        });
        return response;
      } catch (error) {
        return error.response;
      }
    };

    // GET TICKETS TYPES
    const getTicketTypes = async () => {
      try {
        const response = await axios.get(`${api}/events/tickets`, {
          headers: {
            Authorization: `Bearer ${auth.userToken}`,
            "user-x-key": auth.userId,
          },
        });
        return response;
      } catch (error) {
        return error.response;
      }
    };

    const createTicket = async (data) => {
      try {
        const response = await axios.post(`${api}/tickets/create`, data, {
          headers: {
            Authorization: `Bearer ${auth.userToken}`,
            "user-x-key": auth.userId,
          },
        });
        return response;
      } catch (error) {
        return error.response;
      }
    };

    const updateTicket = async (data) => {
      try {
        const response = await axios.put(`${api}/tickets/update`, data, {
          headers: {
            Authorization: `Bearer ${auth.userToken}`,
            "user-x-key": auth.userId,
          },
        });

        return response;
      } catch (error) {
        return error.response;
      }
    };

    const getAddedTickets = async () => {
      try {
        const response = await axios.get(
          `${api}/tickets/get/${sessionStorage.getItem("createdEventId")}`,
          {
            headers: {
              Authorization: `Bearer ${auth.userToken}`,
              "user-x-key": auth.userId,
            },
          }
        );
        return response;
      } catch (error) {
        return error.response;
      }
    };

    const createCalendar = async (data) => {
      try {
        const response = await axios.post(`${api}/events/add.calendar`, data, {
          headers: {
            Authorization: `Bearer ${auth.userToken}`,
            "user-x-key": auth.userId,
          },
        });

        return response;
      } catch (error) {
        return error.response;
      }
    };

    const createCoupon = async (data) => {
      try {
        const response = await axios.post(`${api}/coupons/add`, data, {
          headers: {
            Authorization: `Bearer ${auth.userToken}`,
            "user-x-key": auth.userId,
          },
        });

        return response;
      } catch (error) {
        return error.response;
      }
    };

    const createSTaffMember = async (data) => {
      try {
        const response = await axios.post(`${api}/staff.member/add`, data, {
          headers: {
            Authorization: `Bearer ${auth.userToken}`,
            "user-x-key": auth.userId,
          },
        });

        return response;
      } catch (error) {
        return error.response;
      }
    };

    const getStaffMember = async () => {
      try {
        const response = await axios.get(
          `${api}/staff.member/get/${sessionStorage.getItem("createdEventId")}`,
          {
            headers: {
              Authorization: `Bearer ${auth.userToken}`,
              "user-x-key": auth.userId,
            },
          }
        );
        return response;
      } catch (error) {
        return error.response;
      }
    };

    return {
      createEvent,
      createTicket,
      createCalendar,
      createCoupon,
      createSTaffMember,
      getCategories,
      getTicketTypes,
      getAddedTickets,
      getStaffMember,
      updateTicket,
      event,
    };
  },
  {
    persist: {
      paths: ["event"],
      storage: localStorage,
    },
  }
);
